<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">
                        Vincular Produtos à Categorias de Imposto
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            sort-by="descricao"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 180"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :search="search"
                        >
                            <template v-slot:item.valor="{ item }">
                                <span style="color: green;">R$&nbsp;{{ (+item.valor).toFixed(2) }}</span>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-select
                                    v-model="item.categoria_imposto_id"
                                    @change="onChangeImposto($event, item.id)"
                                    :items="categoriasImposto"
                                    label="Categoria de Imposto"
                                    item-value="id"
                                    :item-text="e => `${e.id} | ${e.nome}`"
                                    hide-details
                                    clearable
                                    outlined
                                    dense
                                />
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'ProdutoImposto',

    components: { BaseContainer },

    data: () => ({
        search: '',
        loading: true,
        dados: [],
        categoriasImposto: [],
        headers: [
            { text: 'Produto', value: 'descricao' },
            { text: 'Grupo', value: 'grupo.descricao' },
            { text: 'Valor', value: 'valor' },
            { text: 'Categoria de Imposto', value: 'action', sortable: false, align: 'center' },
        ],
    }),

    async mounted() {
        await this.getCategoriasImposto();
        this.consultar();
    },

    methods: {
        consultar() {
            this.loading = true;
            this.$http.get('produtos').then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
                this.loading = false;
            });
        },

        async getCategoriasImposto() {
            const { data } = await this.$http.get('categorias-imposto');
            this.categoriasImposto = data.data;
        },

        onChangeImposto(impostoId, produtoId) {
            console.log('==> ', impostoId, produtoId)

            this.$http.put(`produtos/update-imposto/${produtoId}`, { categoria_imposto_id: impostoId }).then(() => {
                this.notify('Atualizado com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },
    },
}
</script>
